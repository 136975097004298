import React, { useEffect, useState } from "react";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";
import Search from "../../../assets/Icon/Search.svg";
import close from "../../../assets/Icon/close.svg";
import DatePicker from "react-datepicker";
import Calender from "../../../assets/Icon/calendar.svg";
import Select from "react-dropdown-select";
import ApiCalling from "../../../network/ApiCalling";
import Loader from "../../../components/common/Loader";
import Sort from "../../../assets/Icon/Short.svg";
import { Dropdown } from "react-bootstrap";
import AddObservation from "../Observations/AddObservation";
import ToastHOC from "../../HOC/ToastHOC";
import ModalComponent from "../ModalComponent";
import moment from "moment";
import ViewObservation from "../Observations/ViewObservation";
import BreadCrumbComponent from "../../../components/BreadCrumbComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const StudentObservation = (props) => {
  const getLanguage = Util.getSelectedLanguage();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(props.location.search);
  const studentIdParam = queryParams.get("studentId");
  const studentId = props.history.location.studentId || studentIdParam;
  const studentName = props.history.location?.studentName;
  const history = useHistory();
  const { setIsToast } = props;
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [date, setDate] = useState(null);
  const [observationType, setObservationType] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [observationTypeId, setObservationTypeId] = useState(null);
  const [observationList, setObservationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [observationById, setObservationById] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const school = Util.getSelectedSchool();
  const [isView, setIsView] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setSearchClick(search);
    }
  };

  const searchClicked = () => {
    if (search.length > 0) {
      setSearch("");
      setSearchClick("");
    } else {
      // setCurrentPage(1);
      setSearchClick(search);
    }
  };

  const ChangeDate = (range) => {
    if (range) {
      // setCurrentPage(1);
      setDate(new Date(range));
    }
  };

  const getObservationTypeList = () => {
    ApiCalling.apiCallParamsGet("observation-type/list")
      .then((res) => {
        setObservationType(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getObservationTypeList();
    getStudentObservationList();
  }, []);

  useEffect(() => {
    getStudentObservationList();
  }, [searchClick, date, observationTypeId]);

  const CustomInput = React.forwardRef(({ value, onClick, icon }, ref) => (
    <div className="d-flex" onClick={onClick}>
      <input
        ref={ref}
        className="custom-input__field"
        value={value}
        readOnly
        placeholder="Date"
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calendar" className="svg-icon calender-icon" />
      </div>
    </div>
  ));

  const changeType = (data) => {
    setCurrentPage(1);
    setObservationTypeId(data[0].id);
  };

  const getStudentObservationList = () => {
    if (date) {
      const selectedDate = new Date(date);
      const utcDate = new Date(
        Date.UTC(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate()
        )
      );
      var isoDate = utcDate.toISOString().split("T")[0];
    }
    const body = {
      studentId: studentIdParam,
      str_search: searchClick,
      date: isoDate,
      type_id: observationTypeId,
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost(`observations/listByStudentId`, body)
      .then((res) => {
        setObservationList(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`observations/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setIsToast(t("Deleted Observations Successfully"));
        setShowDeleteConfirmation(false);
        getStudentObservationList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      {/* <div className="container-fluid">
        <div className="body">
          <div className="col-lg col-md-12 p-3">
            <div className="row py-3">
              <div
                className={`col-md-12  ${getLanguage !== "ar" && "text-end"}`}
              >
                {studentId > 0 && (
                  <BreadCrumbComponent
                    Breadcrumb={[
                      {
                        name: "Progress Tracking",
                        // navigate: () => {
                        //   history.push("/Dashboard");
                        // },
                      },
                      {
                        name: "Observations",
                        navigate: () => {
                          history.push("/observation-list");
                        },
                      },
                      { name: `${studentName}` },
                    ]}
                  />
                )}
              </div>

            </div>
          </div>
        </div>
      </div> */}
      <div className="container-fluid">
        <div className="body">
          <div className="col-lg col-md-12 p-3">
            <div className="row pb-3">
              <div
                className={`col-md-12  ${getLanguage !== "ar" && "text-end"}`}
              >
                {studentId > 0 && (
                  <BreadCrumbComponent
                    Breadcrumb={[
                      {
                        name: "Progress Tracking",
                        // navigate: () => {
                        //   history.push("/Dashboard");
                        // },
                      },
                      {
                        name: "Observations",
                        navigate: () => {
                          history.push("/observation-list");
                        },
                      },
                      { name: `${studentName}` },
                    ]}
                  />
                )}
              </div>
            </div>

            <div className={"table-div"}>
              <div className="d-flex gap-20 align-items-center table-sort-bar">
                <div className="custom-search-wrap">
                  <div className="input-group">
                    <input
                      className="form-control search "
                      placeholder={t("Search Observations")}
                      type="text"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      onKeyPress={(e) => EnterHandler(e)}
                    />
                    <div className="input-group-prepend">
                      <div className="search-icon">
                        <img
                          src={search.length > 0 ? close : Search}
                          className="svg-icon"
                          alt=""
                          onClick={searchClicked}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mr-2">
                  <DatePicker
                    placeholderText="Select Date"
                    selected={date}
                    onChange={ChangeDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    popperPlacement="bottom"
                    customInput={<CustomInput icon={Calender} />}
                    maxDate={new Date()}
                  />
                </div>
                <div className="mr-2">
                  <Select
                    searchable={false}
                    className="select-custom text-nowrap"
                    options={[...observationType]}
                    onChange={(values) => changeType(values)}
                    style={{ width: "100%" }}
                    labelField="type"
                    values={[{ value: "all", type: "Type" }]}
                    valueField="id"
                  />
                </div>
              </div>
              {isLoading ? (
                <Loader />
              ) : (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-wrapper">
                      <table className="table">
                        <thead>
                          <tr className="text-nowrap">
                            <th
                              scope="col"
                              className="check-col"
                              style={{
                                width: 0,
                              }}
                            >
                              {t("Title")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("observations.title");
                                }}
                              />
                            </th>
                            <th
                              scope="col"
                              className="check-col"
                              style={{
                                width: 0,
                              }}
                            >
                              {t("Date")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("observations.date");
                                }}
                              />
                            </th>
                            <th
                              scope="col"
                              className="check-col"
                              style={{
                                width: 0,
                              }}
                            >
                              {t("Type")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("observations.date");
                                }}
                              />
                            </th>
                            {/* <th
                            scope="col"
                            className="check-col"
                            style={{
                              width: 0,
                            }}
                          >
                            {t("Shared With Parents")}
                            <img
                              src={Sort}
                              className="ml-1 fa-sort"
                              alt=""
                              onClick={() => {
                                handleSort("observations.date");
                              }}
                            />
                          </th> */}
                            <th
                              scope="col"
                              className="check-col"
                              style={{
                                width: 0,
                              }}
                            >
                              {t("Actions")}
                            </th>
                          </tr>
                        </thead>
                        {observationList?.length > 0 ? (
                          <>
                            <tbody>
                              {observationList.map((item, index) => {
                                return (
                                  <tr>
                                    <td
                                      style={{
                                        paddingRight: "0px",
                                        width: 0,
                                      }}
                                    >
                                      {item?.title}
                                    </td>
                                    <td
                                      style={{
                                        paddingRight: "0px",
                                        width: 0,
                                      }}
                                    >
                                      {item?.date
                                        ? moment(item?.date).format(
                                            Util.getMomentDateTimeFormat(
                                              school.date_format
                                            )
                                          )
                                        : "-"}
                                    </td>
                                    <td
                                      style={{
                                        paddingRight: "0px",
                                        width: 0,
                                      }}
                                    >
                                      {item?.ObservationType?.type}
                                    </td>
                                    {/* <td
                                    style={{
                                      paddingRight: "0px",
                                      width: 0,
                                    }}
                                  >
                                    {item?.share_with_parents ? item?.share_with_parents : "-"}
                                  </td> */}
                                    <td
                                      style={{
                                        paddingRight: "0px",
                                        width: 0,
                                      }}
                                    >
                                      <Dropdown>
                                        <Dropdown.Toggle variant="none">
                                          <button>
                                            <i
                                              className="fa fa-ellipsis-v"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setIsView(true);
                                              setSelectedId(item?.id);
                                            }}
                                          >
                                            View
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            // className={`${
                                            //   !Util.hasPermission(
                                            //     "MASTERS_EDIT"
                                            //   ) && "no-permission"
                                            // }`}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setIsVisible(true);
                                              setObservationById(item?.id);
                                            }}
                                          >
                                            Edit
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            // className={`${
                                            //   !Util.hasPermission(
                                            //     "MASTERS_DELETE"
                                            //   ) && "no-permission"
                                            // }`}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setShowDeleteConfirmation(true);
                                              setDeleteId(item.id);
                                            }}
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </>
                        ) : (
                          <>
                            <tfoot>
                              <tr>
                                <td colSpan={8}>
                                  {t(
                                    "No Observations Found! Please recheck the filter or Create One"
                                  )}
                                </td>
                              </tr>
                            </tfoot>
                          </>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <AddObservation
          show={isVisible}
          onClose={() => {
            setIsVisible(false);
          }}
          observationById={observationById}
          onSave={(saveMsg) => {
            setIsVisible(false);
            getStudentObservationList();
            setIsToast(saveMsg);
            setObservationById(null);
          }}
        />
        <ViewObservation
          show={isView}
          onClose={() => {
            setIsView(false);
          }}
          observationById={selectedId}
        />
        <ModalComponent
          show={showDeleteConfirmation}
          onHide={() => {
            setShowDeleteConfirmation(false);
          }}
          title={t("Delete")}
          onCancel={t("Cancel")}
          onConfirm={t("Confirm")}
          subTitle={t("Are you sure, to delete this Observation?")}
          size="sm"
          onCancelClick={() => {
            setShowDeleteConfirmation(false);
          }}
          onConfirmClick={handleDelete}
        />
      </div>
    </div>
  );
};

export default ToastHOC(StudentObservation);
