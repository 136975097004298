import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MealPlanList from "../MealPlan/MealPlanList";
import { useTranslation } from "react-i18next";

export default function VerticalTabs({
  a11yProps,
  value,
  handleChange,
  TabPanel,
}) {
  const { t } = useTranslation();
  const tabStyle = {
    textTransform: "capitalize",
    height: "40px",
    textAlign: "left !important",
  };
  const lastTab = {
    marginBottom: "30px"
  }
  return (
    <div className="vertical-tab-setting">
      <Box>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <Tab style={tabStyle} label={t("School Info")} {...a11yProps(0)} />
          <Tab style={tabStyle} label={t("Meal Plans")} {...a11yProps(1)} />
          <Tab style={tabStyle} label={t("Pickup Info")} {...a11yProps(2)} />
          <Tab style={tabStyle} label={t("Role")} {...a11yProps(3)} />
          <Tab style={tabStyle} label={t("Timing")} {...a11yProps(4)} />
          <Tab
            style={tabStyle}
            label={t("Activity Category")}
            {...a11yProps(5)}
          />
          <Tab style={tabStyle} label={t("Subject")} {...a11yProps(6)} />
          <Tab style={tabStyle} label={t("Room")} {...a11yProps(7)} />
          <Tab style={tabStyle} label={t("Fee Types")} {...a11yProps(8)} />
          <Tab style={tabStyle} label={t("Terms")} {...a11yProps(9)} />
          <Tab style={tabStyle} label={t("Groups")} {...a11yProps(10)} />
          <Tab style={tabStyle} label={t("Leave Types")} {...a11yProps(11)} />
          <Tab
            style={tabStyle}
            label={t("Announcement Types")}
            {...a11yProps(12)}
          />
          <Tab style={tabStyle} label={t("Payment keys")} {...a11yProps(13)} />
          <Tab style={tabStyle} label={t("Taxes")} {...a11yProps(14)} />
          <Tab style={{...tabStyle,...lastTab}} label={t("Observations")} {...a11yProps(15)} />
        </Tabs>
      </Box>
    </div>
  );
}
