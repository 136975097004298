import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../components/common/Logo";
import dashboard from "../assets/Icon/dashboard.svg";
import home from "../assets/Icon/home.svg";
import sidebarToggle from "../assets/Icon/sidebar-toggle.svg";
import building from "../assets/Icon/building-school.svg";
import Attendance from "../assets/Icon/time-check.svg";
import Calander from "../assets/Icon/calendar.svg";
import Activity from "../assets/Icon/activity.svg";
import Messaging from "../assets/Icon/chat.svg";
import Groups from "../assets/Icon/cash-money-alt.svg";
import Announcment from "../assets/Icon/megaphone-alt.svg";
import Biling from "../assets/Icon/bill.svg";
import Reporting from "../assets/Icon/Reporting.svg";
import Setting from "../assets/Icon/setting.svg";
import Help from "../assets/Icon/help.svg";
import { Dropdown, Modal, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import ApiCalling from "../network/ApiCalling";
import Util from "../Util";
import { SocketContext } from "../context/SocketContext";
import { useDispatch, useSelector } from "react-redux";
import { Toggle } from "../actions/ToggleAction";
import { setUnreadMessagesCount } from "../actions/SetUnreadMessagesAction";

export default function Sidebar() {
  const { t } = useTranslation();
  const location = useLocation();
  const user = Util.getUser();
  const dispatch = useDispatch();
  const getLanguage = Util.getSelectedLanguage();
  const socket = useContext(SocketContext);
  const [activeTabReference, setActiveTabReference] = useState("");
  const [isMySchoolOpen, setIsMySchoolOpen] = useState(false);
  const [isAttendanceOpen, setIsAttendanceOpen] = useState(false);
  const [isProgressTrackingOpen, setIsProgressTrackingOpen] = useState(false);
  const [isBillingOpen, setIsBillingOpen] = useState(false);
  const [sidebarToggleHovered, setSidebarToggleHovered] = useState(false);
  const [newUser, setNewUser] = useState(false);

  const { unreadMessagesCount } = useSelector(
    (state) => state.messagingReducer
  );

  useEffect(() => {
    if (user?.user_Schools?.length === 0) {
      setNewUser(true);
    }
  }, []);

  const onBackPress = () => {
    if (getLanguage === "ar") {
      document.getElementById("left-sidebar").style.right = "-260px";
      document.getElementById("main-content").style.width = "100%";
    } else {
      document.getElementById("left-sidebar").style.left = "-260px";
    }

    document.getElementById("main-content").style.width = "100%";
    dispatch(Toggle(true));
  };
  const getListItemClass = () => {
    switch (location.pathname) {
      case "/Dashboard":
      case "/":
        return "dashboard";

      case "/NewsFeed":
        return "NewsFeed";

      case "/student":
      case "/new-student":
      case "/BulkStudent":
      case "/profileTabs":
      case "/parentContact":
        return "student";

      case "/staff":
      case "/new-staff":
      case "/BulkStaff":
      case "/staff-update":
        return "staff";

      case "/attendance-log":
        return "attendance-log";
      case "/leave-approval":
        return "leave-approval";

      case "/appcalendar":
        return "appcalendar";

      case "/activity":
        return "activity";
      case "/messaging":
        return "messaging";
      case "/announcement":
        return "announcement";
      case "/observation-list":
        return "observation-list";
      case "/bill-Dashboard":
        return "bill-Dashboard";
      case "/billing-plan-list":
      case "/student-bill-details":
        return "billing-plan-list";
      case "/bill-reports":
        return "bill-reports";
      case "/reporting":
        return "reporting";
      case "/help":
        return "help";
      case "/settings":
        return "settings";
      case "/parent":
        return "parent";
      default:
        return "";
    }
  };
  const getUnreadMessageCount = async () => {
    ApiCalling.apiCallParamsGet("chatroom/unread/" + user.id)
      .then((res) => {
        dispatch(setUnreadMessagesCount(res.data?.unread));
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };
  const makeUserOnline = async () => {
    if (Util.isValidData(user)) {
      socket.emit("new-user-add", user?.id);

      socket.on("new-message", (res) => {
        console.log(" new-message:", res);

        dispatch(setUnreadMessagesCount(res));
      });
    }
  };
  const handleFocus = async () => {
    console.log("onFocus ***************");
    // console.log("==========================");
    await makeUserOnline();
  };

  useEffect(() => {
    const reference = getListItemClass();
    setActiveTabReference(reference);
  }, [location.pathname]);
  useEffect(() => {
    const fetchData = async () => {
      console.log("1.11");
      window.addEventListener("focus", handleFocus);
      await makeUserOnline();
      getUnreadMessageCount();
    };
    fetchData();
    return () => {
      console.log("1.13");
      window.removeEventListener("focus", handleFocus);
    };
  }, []);
  return (
    <div>
      <div id="left-sidebar" className="sidebar offcanvas-body">
        <div
          className="d-flex justify-content-between align-items-center mx-2 mt-2"
          style={{ paddingTop: "10px", paddingLeft: "10px" }}
        >
          <Logo wrapperClass={"logo-inside"} width="90%" />
          <IconButton
            aria-label="close"
            size="small"
            className={`float-right ${
              sidebarToggleHovered ? "d-inline" : "opacity-0"
            }`}
            onClick={(e) => {
              e.preventDefault();
              onBackPress();
            }}
            onMouseEnter={() => setSidebarToggleHovered(true)}
            onMouseLeave={() => setSidebarToggleHovered(false)}
          >
            <img src={sidebarToggle} alt="menu" />
          </IconButton>
        </div>
        <div className="sidebar-scroll">
          <div
            // className={sideMenuTab[0] ? "tab-pane active show" : "tab-pane"}
            className={"tab-pane"}
            id="menu"
          >
            <Nav id="left-sidebar-nav" className="sidebar-nav mt-4">
              {newUser ? (
                <ul id="main-menu" className="metismenu">
                  <li
                    className={
                      activeTabReference === "dashboard" ? "active" : ""
                    }
                    onClick={() => {
                      setIsMySchoolOpen(false);
                      setIsAttendanceOpen(false);
                      setIsBillingOpen(false);
                      setIsProgressTrackingOpen(false);
                    }}
                  >
                    <Link to="Dashboard">
                      <i>
                        <img
                          src={home}
                          alt="Dashboard"
                          className="svg-icon"
                          width={20}
                          height={20}
                        />
                      </i>
                      {t("Dashboard")}
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul id="main-menu" className="metismenu">
                  <li
                    className={
                      activeTabReference === "dashboard" ? "active" : ""
                    }
                    onClick={() => {
                      setIsMySchoolOpen(false);
                      setIsAttendanceOpen(false);
                      setIsBillingOpen(false);
                      setIsProgressTrackingOpen(false);
                    }}
                  >
                    <Link to="Dashboard">
                      <i>
                        <img
                          src={home}
                          alt="Dashboard"
                          className="svg-icon"
                          width={20}
                          height={20}
                        />
                      </i>
                      {t("Dashboard")}
                    </Link>
                  </li>
                  <li
                    className={
                      activeTabReference === "NewsFeed" ? "active" : ""
                    }
                    onClick={() => {
                      setIsMySchoolOpen(false);
                      setIsAttendanceOpen(false);
                      setIsBillingOpen(false);
                      setIsProgressTrackingOpen(false);
                    }}
                  >
                    <Link to="NewsFeed">
                      <i>
                        <img
                          src={dashboard}
                          alt="News Feed"
                          className="svg-icon"
                        />
                      </i>
                      {t("News Feed")}
                    </Link>
                  </li>

                  <li
                    id="SchoolContainer"
                    className={`${
                      (activeTabReference === "student" ? "active" : "") ||
                      (activeTabReference === "staff" ? "active" : "") ||
                      (activeTabReference === "parent" ? "active" : "") ||
                      (isMySchoolOpen ? "active" : "")
                    }`}
                  >
                    <a
                      className="has-arrow"
                      onClick={() => {
                        setIsMySchoolOpen(!isMySchoolOpen);
                        setIsAttendanceOpen(false);
                        setIsBillingOpen(false);
                        setIsProgressTrackingOpen(false);
                      }}
                    >
                      <i>
                        <img
                          src={building}
                          alt="building"
                          className="svg-icon"
                        />
                      </i>
                      <span>{t("My School")}</span>
                    </a>
                    <ul className={`collapse ${isMySchoolOpen ? "in" : ""}`}>
                      <li
                        className={
                          activeTabReference === "student" ? "active" : ""
                        }
                      >
                        <Link to="student">{t("Students")}</Link>
                      </li>
                      <li
                        className={
                          activeTabReference === "staff" ? "active" : ""
                        }
                      >
                        <Link to="staff">{t("Staff")}</Link>
                      </li>
                      <li
                        className={
                          activeTabReference === "parent" ? "active" : ""
                        }
                      >
                        <Link to="parent">{t("Parents")}</Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    id="AttendanceContainer"
                    className={`${
                      (activeTabReference === "attendance-log"
                        ? "active"
                        : "") ||
                      (activeTabReference === "attendance-log"
                        ? "active"
                        : "") ||
                      (isAttendanceOpen ? "active" : "")
                    }`}
                  >
                    <a
                      // href="#!"
                      className="has-arrow"
                      onClick={() => {
                        setIsAttendanceOpen(!isAttendanceOpen);
                        setIsBillingOpen(false);
                        setIsMySchoolOpen(false);
                        setIsProgressTrackingOpen(false);
                      }}
                    >
                      <i>
                        <img
                          src={Attendance}
                          alt="Attendance"
                          className="svg-icon"
                        />
                      </i>
                      <span>{t("Attendance")}</span>
                    </a>

                    <ul className={`collapse ${isAttendanceOpen ? "in" : ""}`}>
                      <li
                        className={
                          activeTabReference === "attendance-log"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="attendance-log">{t("Attendance Log")}</Link>
                      </li>

                      <li
                        className={
                          activeTabReference === "leave-approval"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="leave-approval">{t("Leave Requests")}</Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={
                      activeTabReference === "appcalendar" ? "active" : ""
                    }
                    onClick={() => {
                      setIsMySchoolOpen(false);
                      setIsAttendanceOpen(false);
                      setIsBillingOpen(false);
                      setIsProgressTrackingOpen(false);
                    }}
                  >
                    <Link to="appcalendar">
                      <i>
                        <img
                          src={Calander}
                          alt="Calander"
                          className="svg-icon"
                        />
                      </i>
                      {t("Calendar")}
                    </Link>
                  </li>
                  <li
                    className={
                      activeTabReference === "activity" ? "active" : ""
                    }
                    onClick={() => {
                      setIsMySchoolOpen(false);
                      setIsAttendanceOpen(false);
                      setIsBillingOpen(false);
                      setIsProgressTrackingOpen(false);
                    }}
                  >
                    <Link to="activity">
                      <i>
                        <img
                          src={Activity}
                          alt="Activity"
                          className="svg-icon"
                        />
                      </i>
                      {t("Activity")}
                    </Link>
                  </li>

                  <li
                    className={
                      activeTabReference === "messaging" ? "active" : ""
                    }
                    onClick={() => {
                      setIsMySchoolOpen(false);
                      setIsAttendanceOpen(false);
                      setIsBillingOpen(false);
                      setIsProgressTrackingOpen(false);
                    }}
                  >
                    <Link to="messaging">
                      <div
                        className={
                          getLanguage !== "ar"
                            ? "d-flex align-items-center justify-content-between"
                            : ""
                        }
                      >
                        <div>
                          <i>
                            <img
                              src={Messaging}
                              alt="Messaging"
                              className="svg-icon"
                            />
                          </i>

                          <span>{t("Messaging")}</span>
                        </div>
                        {unreadMessagesCount > 0 && (
                          <span
                            style={{
                              marginTop: getLanguage == "ar" ? "-18px" : "",
                            }}
                            className="navbar-counter-badge"
                          >
                            {unreadMessagesCount}
                          </span>
                        )}
                      </div>
                    </Link>
                  </li>

                  <li
                    className={
                      activeTabReference === "announcement" ? "active" : ""
                    }
                    onClick={() => {
                      setIsMySchoolOpen(false);
                      setIsAttendanceOpen(false);
                      setIsBillingOpen(false);
                      setIsProgressTrackingOpen(false);
                    }}
                  >
                    <Link to="announcement">
                      <i>
                        <img
                          src={Announcment}
                          alt="Announcement"
                          className="svg-icon"
                        />
                      </i>
                      {t("Announcement")}
                    </Link>
                  </li>

                  <li
                    id="SchoolContainer"
                    className={`${
                      (activeTabReference === "observation-list"
                        ? "active"
                        : "") || (isProgressTrackingOpen ? "active" : "")
                    }`}
                  >
                    <a
                      className="has-arrow"
                      onClick={() => {
                        setIsMySchoolOpen(false);
                        setIsAttendanceOpen(false);
                        setIsBillingOpen(false);
                        setIsProgressTrackingOpen(!isProgressTrackingOpen);
                      }}
                    >
                      <i>
                        <img
                          src={building}
                          alt="building"
                          className="svg-icon"
                        />
                      </i>
                      <span>{t("Progress Tracking")}</span>
                    </a>
                    <ul
                      className={`collapse ${
                        isProgressTrackingOpen ? "in" : ""
                      }`}
                    >
                      <li
                        className={
                          activeTabReference === "observation-list"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="observation-list">{t("Observations")}</Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    id="BillingContainer"
                    className={`${
                      (activeTabReference === "bill-Dashboard"
                        ? "active"
                        : "") ||
                      (activeTabReference === "billing-plan-list"
                        ? "active"
                        : "") ||
                      (activeTabReference === "bill-reports" ? "active" : "") ||
                      (isBillingOpen ? "active" : "")
                    }`}
                  >
                    <a
                      className="has-arrow"
                      onClick={() => {
                        setIsBillingOpen(!isBillingOpen);
                        setIsMySchoolOpen(false);
                        setIsAttendanceOpen(false);
                        setIsProgressTrackingOpen(false);
                      }}
                    >
                      <i>
                        <img src={Biling} alt="Biling" className="svg-icon" />
                      </i>
                      <span>{t("Billing")}</span>
                    </a>
                    <ul className={`collapse ${isBillingOpen ? "in" : ""}`}>
                      <li
                        className={
                          activeTabReference === "bill-Dashboard"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="bill-Dashboard">{t("Dashboard")}</Link>
                      </li>
                      <li
                        className={
                          activeTabReference === "billing-plan-list"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="billing-plan-list">{t("Students")}</Link>
                      </li>
                      <li
                        className={
                          activeTabReference === "bill-reports" ? "active" : ""
                        }
                      >
                        <Link to="bill-reports">{t("Reports")}</Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={
                      activeTabReference === "reporting" ? "active" : ""
                    }
                    onClick={() => {
                      setIsMySchoolOpen(false);
                      setIsAttendanceOpen(false);
                      setIsBillingOpen(false);
                      setIsProgressTrackingOpen(false);
                    }}
                  >
                    <Link to="reporting">
                      <i>
                        <img
                          src={Reporting}
                          alt="Reporting"
                          className="svg-icon"
                        />
                      </i>
                      {t("Reporting")}
                    </Link>
                  </li>

                  <li
                    className={
                      activeTabReference === "settings" ? "active" : ""
                    }
                    onClick={() => {
                      setIsMySchoolOpen(false);
                      setIsAttendanceOpen(false);
                      setIsBillingOpen(false);
                      setIsProgressTrackingOpen(false);
                    }}
                  >
                    <Link to="settings">
                      <i>
                        <img src={Setting} alt="Setting" className="svg-icon" />
                      </i>
                      {t("Settings")}
                    </Link>
                  </li>
                </ul>
              )}
            </Nav>
          </div>
        </div>
      </div>
    </div>
  );
}
