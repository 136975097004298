import React, { useState, useEffect, useRef } from "react";
import { Avatar, Box, SwipeableDrawer } from "@mui/material";
import Util from "../../../Util";
import Close from "../../../assets/Icon/close.png";
import { useTranslation } from "react-i18next";
import ApiCalling from "../../../network/ApiCalling";
import Search from "../../../assets/Icon/Search.svg";
import check from "../../../assets/Icon/check-box.svg";
import emptyCheck from "../../../assets/Icon/check-box-empty.svg";
import { imageStudentList, observations } from "../../CommonImage";
import Loader from "../../../components/common/Loader";
import DatePicker from "react-datepicker";
import Calander from "../../../assets/Icon/calendar.svg";
import Invalid from "../../../assets/Icon/invalid.svg";
import CustomFileUpload from "../CustomFileUpload";
import ExitAlert from "../../../components/ExitAlert";
import CloseIcon from "@mui/icons-material/Close";
import { PictureAsPdf } from "@mui/icons-material";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ListAltIcon from "@mui/icons-material/ListAlt";

const AddObservation = (props) => {
  const show = props.show;
  const onClose = props.onClose;
  const onSave = props.onSave;
  const { t } = useTranslation();
  const photoREF = useRef();
  const getLanguage = Util.getSelectedLanguage();
  const school = Util.getSelectedSchool();
  const [stepper1, setStepper1] = useState(false);
  const [search, setSearch] = useState("");
  const [isStudentLoading, setIsStudentLoading] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState([]);
  const [selectedStudentsError, setSelectedStudentsError] = useState("");
  const [filteredStudentList, setFilteredStudentList] = useState([]);
  const [searchClick, setSearchClick] = useState("");
  const [classId, setClassId] = useState(0);
  const [classList, setClassList] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [gradeList, setGradeList] = useState([]);
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [createdDate, setCreatedDate] = useState(null);
  const [createdDateError, setCreatedDateError] = useState("");
  const [alertModal, setIsAlertModal] = useState(false);
  const [observationList, setObservationList] = useState([]);
  const [observationTypeId, setObservationTypeId] = useState(0);
  const [observationTypeIdError, setObservationTypeIdError] = useState(0);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [nextSteps, setNextSteps] = useState("");
  const [nextStepsError, setStepsError] = useState("");
  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [documentFileName, setDocumentFileName] = useState("");
  const [observationById, setObservationById] = useState(null);

  const getStudentList = () => {
    setIsStudentLoading(true);
    let data = {
      page_size: 1000,
      page_number: 1,
      classroom_id: classId,
      grade_id: gradeId,
      str_search: searchClick,
      active: "true",
      ascdesc: "asc",
      order_by: "",
    };
    ApiCalling.apiCallBodyDataPost("student/list", data).then((res) => {
      if (res.data.data) {
        setStepper1(true);
        setStudentList(res.data.data);
        setFilteredStudentList(res.data.data);
        setIsStudentLoading(false);
      }
    });
  };

  const changeClassRoom = (e) => {
    setClassId(e.target.value);
    setIsStateUpdated(true);
  };

  const changeGrade = (e) => {
    setGradeId(e.target.value);
    setIsStateUpdated(true);
  };

  const handleAllSelectedStudent = (e) => {
    if (e.target.checked) {
      setSelectedStudentId(studentList.map((item) => item.id));
    } else {
      setSelectedStudentId([]);
    }
    setIsStateUpdated(true);
  };

  const handleRowClick = (id) => {
    if (!selectedStudentId.includes(id)) {
      setSelectedStudentId([...selectedStudentId, id]);
    } else {
      const data = selectedStudentId.filter((s) => s !== id);
      setSelectedStudentId(data);
    }
    setSelectedStudentsError("");
    setIsStateUpdated(true);
  };

  const changeDate = (date) => {
    setCreatedDate(date);
    setCreatedDateError("");
    setIsStateUpdated(true);
  };

  const getClassList = async () => {
    // setLoading(true);
    ApiCalling.apiCallBodyDataGet("classroom/list").then((res) => {
      const modifiedClassList = res.data
        .filter((g) => gradeId == 0 || g.Grade.id == gradeId)
        .map((classroom) => ({
          id: classroom.id,
          name: `${classroom.Grade.title}-${classroom.name}`,
        }));
      setClassList(modifiedClassList);
    });
  };

  const getGradeList = async () => {
    ApiCalling.apiCallBodyDataGet("grade/list").then((res) => {
      setGradeList(res.data);
    });
  };

  const getObservationList = () => {
    ApiCalling.apiCallParamsGet("observation-type/list")
      .then((res) => {
        setObservationList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setStepper1(true);
    getClassList();
    getGradeList();
    getObservationList();
  }, []);

  useEffect(() => {
    if (observationById === null) {
      reset();
      setObservationById(null);
      setCreatedDate(new Date());
    } else {
      actById();
    }
  }, [observationById]);

  const actById = () => {
    ApiCalling.apiCallParamsGet(`observations/get-id/${observationById}`)
      .then((res) => {
        console.log("res", res);
        if (res?.data?.observations[0]) {
          const editData = res?.data?.observations[0];
          if (editData?.ObservationStudents) {
            editData?.ObservationStudents.map((item) => {
              setSelectedStudentId((prevSelected) => [
                ...prevSelected,
                item?.student_id,
              ]);
            });
          }
          if (editData?.ObservationType) {
            setObservationTypeId(editData?.ObservationType?.id);
          }
          setCreatedDate(new Date(editData?.date));
          setTitle(editData?.title);
          setDescription(editData?.description);
          setNextSteps(editData?.next_steps);
          setFile(observations + editData?.ObservationMedia[0]?.name);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getStudentList();
  }, [classId, gradeId, searchClick]);

  useEffect(() => {
    setClassId(0);
    getClassList();
  }, [gradeId]);

  useEffect(() => {
    if (props?.observationById) {
      setObservationById(props.observationById);
    }
  }, [props]);

  const CustomInput = ({ value, onClick, icon }) => (
    <div className=" d-flex" onClick={onClick}>
      <input className="custom-input__field" value={value} readOnly />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon calender-icon" />
      </div>
    </div>
  );

  const handleObservationType = (e) => {
    setObservationTypeId(e.target.value);
    setObservationTypeIdError("");
    setIsStateUpdated(true);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
    setTitleError("");
    setIsStateUpdated(true);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setDescriptionError("");
    setIsStateUpdated(true);
  };

  const handleNextStepsChange = (e) => {
    setNextSteps(e.target.value);
    setIsStateUpdated(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleDocumentChange(file);
    setFileError("");
    setIsStateUpdated(true);
  };

  const handleDocumentDragOver = (e) => {
    e.preventDefault();
  };

  const handleDocumentDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleDocumentChange(file);
  };

  const handleDocumentChange = (file) => {
    console.log(file);
    if (file) {
      const maxSize = 50 * 1024 * 1024;
      if (file.size <= maxSize) {
        setFile(file);
        setFileURL(URL.createObjectURL(file));
      } else {
        setFileError(t("File size exceeds the limit of 50MB"));
      }
    }
  };

  const reset = () => {
    setIsStateUpdated(false);
    setObservationTypeIdError("");
    setCreatedDateError("");
    setTitleError("");
    setObservationTypeId(0);
    setCreatedDate(new Date());
    setTitle("");
    setDescription("");
    setNextSteps("");
    setFile("");
    setFileError("");
    setSelectedStudentsError("");
    setSelectedStudentId([]);
    setStepper1(true);
  };

  const handleSave = () => {
    setIsStateUpdated(false);
    setObservationTypeIdError("");
    setCreatedDateError("");
    setTitleError("");

    let error = false;
    if (observationTypeId === 0) {
      setObservationTypeIdError(t("Type is required"));
      error = true;
    }
    if (createdDate === null) {
      setCreatedDateError(t("Date is required"));
      error = true;
    }
    if (title === "") {
      setTitleError(t("Title is required"));
      error = true;
    }
    if (!error) {
      if (observationById !== null) {
        const formdata = new FormData();
        formdata.append("id", observationById);
        formdata.append("title", title);
        formdata.append("observation_type_id", observationTypeId);
        formdata.append("date", new Date(createdDate).toISOString());
        formdata.append("description", description);
        formdata.append("next_steps", nextSteps);
        formdata.append("student_ids", selectedStudentId);
        formdata.append("observation", file);
        // for (let [key, value] of formdata.entries()) {
        //   console.log(key, value);
        // }
        ApiCalling.apiCallBodyDataPost(`observations/update`, formdata)
          .then((res) => {
            // console.log("Observations Added::", res.data);
            console.log("data", res);
            if (res?.data?.status) {
              onSave(t("Observations Updated"));
              reset();
            } else {
              onSave("Unable to process update observation");
              reset();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const formdata = new FormData();
        formdata.append("title", title);
        formdata.append("observation_type_id", observationTypeId);
        formdata.append("date", new Date(createdDate).toISOString());
        formdata.append("description", description);
        formdata.append("next_steps", nextSteps);
        formdata.append("student_ids", selectedStudentId);
        formdata.append("observation", file);
        // for (let [key, value] of formdata.entries()) {
        //   console.log(key, value);
        // }
        ApiCalling.apiCallBodyDataPost(`observations/create`, formdata)
          .then((res) => {
            // console.log("Observations Added::", res.data);
            console.log("data", res);
            if (res?.data?.status) {
              onSave(t("Observations Created"));
              reset();
            } else {
              onSave("Unable to process create observation");
              reset();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const isImage = (attachment) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const extension = attachment.split(".").pop();
    return imageExtensions.includes(extension.toLowerCase());
  };

  const isVideo = (attachment) => {
    const videoExtensions = ["mp4"];
    const extension = attachment.split(".").pop();
    return videoExtensions.includes(extension.toLowerCase());
  };

  const isDocument = (attachment) => {
    const docExtensions = ["pdf", "doc", "docx", "xls", "xlsx"];
    const extension = attachment.split(".").pop();
    return docExtensions.includes(extension.toLowerCase());
  };
  const getDocumentIcon = (attachment) => {
    const extension = attachment.split(".").pop().toLowerCase();
    switch (extension) {
      case ("xls", "xlsx"):
        return <ListAltIcon fontSize="large" className="text-color-primary" />;
      case "pdf":
        return <PictureAsPdf fontSize="large" className="text-color-primary" />;
      case ("doc", "docx"):
        return (
          <FolderCopyIcon fontSize="large" className="text-color-primary" />
        );

      default:
        return (
          <FolderCopyIcon fontSize="large" className="text-color-primary" />
        );
    }
  };

  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={show}
        onClose={() => {
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            setObservationById(null);
            onClose();
            reset();
            setIsStateUpdated(false);
          }
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{
            position: "relative",
            width: "100%",
            height: "calc(100vh - 64px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">
                {observationById !== null
                  ? t("Edit Observation")
                  : t("Add Observation")}
              </div>
              <button className="hidden-btn">
                <img
                  src={Close}
                  alt="Close"
                  className="svg-icon"
                  onClick={() => {
                    if (isStateUpdated) {
                      setIsAlertModal(true);
                    } else {
                      onClose();
                      setObservationById(null);
                      reset();
                      setIsStateUpdated(false);
                    }
                  }}
                  height={20}
                  width={20}
                />
              </button>
            </div>
            <hr style={{ backgroundColor: "#E5E7EB" }} />
            <div className="body mt-3">
              <div className="row mt-2">
                <div
                  className="d-flex justify-content-between align-items-center mb-1"
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  <div>
                    {stepper1 ? t("Select Students") : t("Observation Details")}
                  </div>
                  <div>{stepper1 ? 1 : 2}/2</div>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <div className="active-stepper"></div>
                  <div
                    className={stepper1 ? "inactive-stepper" : "active-stepper"}
                  ></div>
                </div>

                {stepper1 ? (
                  <>
                    <div className="input-group mt-3">
                      <input
                        className="form-control search"
                        placeholder="Search students"
                        type="text"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                          const searchValue = e.target.value;
                          if (searchValue.trim() === "") {
                            setFilteredStudentList(studentList);
                          } else {
                            const filteredStudent = studentList.filter((s) =>
                              s.first_name
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                            );
                            setFilteredStudentList(filteredStudent);
                          }
                        }}
                      />
                      <div className="input-group-prepend">
                        <div
                          className="search-icon"
                          style={{ paddingRight: "13px" }}
                          //   onClick={searchClicked}
                        >
                          <img src={Search} className="svg-icon" alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="col-6 mt-3">
                      <select
                        className="select-dropdown form-select custom-input"
                        value={gradeId}
                        onChange={changeGrade}
                      >
                        <option value={0}>{t("Select grade")}</option>
                        {gradeList?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-6 mt-3">
                      <select
                        className="select-dropdown form-select custom-input"
                        value={classId}
                        onChange={changeClassRoom}
                      >
                        <option value={0}>{t("Select room")}</option>
                        {classList?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {!isStudentLoading ? (
                      <>
                        <div className="table-container mt-3">
                          <div className="table-wrapper">
                            <table className="table">
                              <thead>
                                <tr className="text-nowrap">
                                  <td
                                    scope="col"
                                    className="check-col"
                                    style={{ paddingRight: "0px", width: 0 }}
                                  >
                                    <label className="fancy-checkbox element-left">
                                      <input
                                        type="checkbox"
                                        name="allselect"
                                        onChange={handleAllSelectedStudent}
                                        checked={
                                          selectedStudentId.length > 0 &&
                                          selectedStudentId.length ===
                                            filteredStudentList.length
                                        }
                                      />
                                      <span tabIndex={0}></span>
                                    </label>
                                  </td>
                                  <th scope="col">
                                    {selectedStudentId.length > 0
                                      ? selectedStudentId.length +
                                        " " +
                                        t("Student Selected")
                                      : t("Student")}
                                  </th>
                                </tr>
                              </thead>
                              {filteredStudentList.length > 0 ? (
                                <tbody>
                                  {filteredStudentList.map((item) => {
                                    return (
                                      <tr
                                        key={item.id}
                                        style={{
                                          backgroundColor: `${
                                            selectedStudentId.includes(item.id)
                                              ? "rgba(100, 24, 195, 0.04)"
                                              : ""
                                          }`,
                                        }}
                                        onClick={() => handleRowClick(item.id)}
                                      >
                                        <td
                                          style={{
                                            cursor: "pointer",
                                            paddingRight: "0px",
                                            width: 0,
                                          }}
                                        >
                                          <img
                                            src={
                                              selectedStudentId.includes(
                                                item.id
                                              )
                                                ? check
                                                : emptyCheck
                                            }
                                          />
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            {item.photo ? (
                                              <img
                                                className="rounded-circle "
                                                style={{
                                                  width: "40px",
                                                  height: "40px",
                                                }}
                                                src={
                                                  imageStudentList + item.photo
                                                }
                                                alt=""
                                              />
                                            ) : (
                                              <Avatar
                                                aria-label="recipe"
                                                className="avatar-font"
                                              >
                                                {item.first_name.toUpperCase()[0] +
                                                  item.last_name.toUpperCase()[0]}
                                              </Avatar>
                                            )}
                                            <a
                                              className="ml-2"
                                              style={{ cursor: "pointer" }}
                                            >
                                              {item.first_name} {item.last_name}
                                            </a>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              ) : (
                                <tfoot>
                                  <tr>
                                    <td colSpan={2}>
                                      {t("Observation Not Found")}
                                    </td>
                                  </tr>
                                </tfoot>
                              )}
                            </table>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </>
                ) : (
                  <>
                    <div className="col-6 text-start mt-3">
                      <span className="basic-text required">{t("Type")}</span>
                      <select
                        name="type"
                        id="type"
                        className={`select-dropdown form-select custom-input ${
                          observationTypeIdError.length > 0 ? "input-error" : ""
                        }`}
                        value={observationTypeId}
                        onChange={handleObservationType}
                      >
                        <option value={0} disabled>
                          {t("Select type")}
                        </option>
                        {observationList.map((item) => {
                          return (
                            <option key={item.key} value={item.id}>
                              {item?.type}
                            </option>
                          );
                        })}
                      </select>
                      {observationTypeIdError.length > 0 && (
                        <div className="invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {observationTypeIdError}
                        </div>
                      )}
                    </div>

                    <div className="col-6 text-start mt-3">
                      <span className="basic-text required">{t("Date")}</span>
                      <div className="input-filed ">
                        <DatePicker
                          placeholderText="Select date"
                          selected={createdDate}
                          onChange={changeDate}
                          dateFormat={school.date_format}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className={`datePicker ${
                            createdDateError.length > 0
                              ? "input-error"
                              : "datePicker"
                          }`}
                          popperPlacement="bottom"
                          customInput={<CustomInput icon={Calander} />}
                          maxDate={new Date()}
                        />

                        {createdDateError.length > 0 && (
                          <div className="invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {createdDateError}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 text-start my-3">
                      <span className="basic-text required">{t("Title")}</span>
                      <input
                        className={`form-control ${
                          titleError.length > 0 ? "input-error" : "custom-input"
                        }`}
                        // placeholder="Email"
                        type="email"
                        onChange={handleChangeTitle}
                        value={title}
                      />
                      {titleError && (
                        <span className="invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {titleError}
                        </span>
                      )}
                    </div>

                    <div className="col-12 text-start mt-3">
                      <span className="basic-text">{t("Description")}</span>
                      <textarea
                        className={`text-area ${
                          descriptionError.length > 0
                            ? "input-error"
                            : " text-area auto-height"
                        }`}
                        placeholder="Write..."
                        rows="4"
                        cols={4}
                        value={description}
                        onChange={handleDescriptionChange}
                      ></textarea>
                      {/* {descriptionError.length > 0 && (
                        <div className="invalid-error px-0">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {descriptionError}
                        </div>
                      )} */}
                    </div>

                    <div className="col-12 text-start mt-3">
                      <span className="basic-text">{t("Next Steps")}</span>
                      <textarea
                        className="text-area auto-height"
                        placeholder="Write..."
                        rows="4"
                        cols={4}
                        value={nextSteps}
                        onChange={handleNextStepsChange}
                      ></textarea>
                    </div>

                    {file === "" && (
                      <div className=" text-start col-12 mt-4">
                        <div className="input-filed">
                          <span className="basic-text">
                            {t("Upload Document")}
                          </span>
                          <CustomFileUpload
                            onChange={handleFileChange}
                            fileName={file ? file.name : documentFileName}
                            showFile={fileURL.length > 0 ? fileURL : ""}
                            onDragOver={handleDocumentDragOver}
                            onDrop={handleDocumentDrop}
                            alertMessage={
                              documentFileName.length > 0 && (
                                <>
                                  {t(
                                    "incase you select new file it will replace old"
                                  )}
                                </>
                              )
                            }
                            acceptedDocument="Image, Video, Doc, PDF (max file 50mb)"
                            accept={"*/*"}
                            className={`${
                              fileError.length > 0
                                ? "custom-file-error"
                                : "custom-file"
                            }`}
                          />
                          {fileError.length > 0 && (
                            <span className="text-danger invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {fileError}
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    {file !== "" && isImage(file?.name || file) && (
                      <div className="row">
                        <div className="col-md-auto g-0">
                          <img
                            crossOrigin="anonymous"
                            style={{
                              maxWidth: "310px",
                              maxHeight: "200px",
                            }}
                            ref={photoREF}
                            src={typeof file === 'string' ? file :URL.createObjectURL(file)}
                          />
                        </div>
                        <div className="col-md-2 g-0">
                          <a onClick={() => setFile("")}>
                            <button className="btn btn-link">
                              <CloseIcon />
                            </button>
                          </a>
                        </div>
                      </div>
                    )}
                    {file !== "" && isVideo(file?.name || file) && (
                      <div className="row">
                        <div className="col-md-4 g-0">
                          <video
                            className="video-responsive"
                            style={{
                              maxHeight: "300px",
                              borderRadius: "10px",
                              padding: "5px",
                            }}
                            src={typeof file === 'string' ? file :URL.createObjectURL(file)}
                            controls
                          />
                        </div>
                        <div className="col-md-2 g-0">
                          <a onClick={() => setFile("")}>
                            <button className="btn btn-link">
                              <CloseIcon />
                            </button>
                          </a>
                        </div>
                      </div>
                    )}
                    {file !== "" && isDocument(file?.name || file) && (
                      <div className="row">
                        <div className="col-md-4 g-0">
                          <a
                            href={typeof file === 'string' ? file :URL.createObjectURL(file)}
                            target="_blank"
                            rel="noreferrer"
                            style={{ display: "block", width: "fit-content" }}
                          >
                            {getDocumentIcon(file?.name)}
                            <br />
                            {file?.name}
                          </a>
                        </div>
                        <div className="col-md-2 g-0">
                          <a onClick={() => setFile("")}>
                            <button className="btn btn-link">
                              <CloseIcon />
                            </button>
                          </a>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            <div
              className="border-top "
              style={{
                position: "fixed",
                width: "656px",
                bottom: "0px",
                right: getLanguage !== "ar" && "0px",
                background: "#ffffff",
                padding: "10px",
              }}
            >
              {stepper1 ? (
                <button
                  className="secondary-md-btn btn ml-2"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  {t("Cancel")}
                </button>
              ) : (
                <button
                  className="secondary-md-btn btn mr-2"
                  onClick={() => {
                    setStepper1(true);
                  }}
                >
                  {t("Previous")}
                </button>
              )}

              {stepper1 ? (
                <>
                  <button
                    style={{ float: "right" }}
                    className="primary-btn btn"
                    onClick={() => {
                      if (selectedStudentId.length > 0) {
                        setStepper1(false);
                      } else {
                        setSelectedStudentsError(t("Please Select Student!"));
                      }
                    }}
                  >
                    {t("Continue")}
                  </button>
                  {selectedStudentsError.length > 0 && (
                    <span
                      className="text-danger"
                      style={{ float: "right", margin: "12px" }}
                    >
                      {selectedStudentsError}
                    </span>
                  )}
                </>
              ) : (
                <button
                  style={{ float: "right" }}
                  className="primary-xl-btn btn"
                  onClick={handleSave}
                >
                  {t("Submit")}
                </button>
              )}
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
      <ExitAlert
        visible={alertModal}
        onClose={() => {
          setIsAlertModal(false);
          setIsStateUpdated(false);
        }}
        onOk={() => {
          onClose();
          setIsAlertModal(false);
          reset();
          setIsStateUpdated(false);
        }}
      />
    </div>
  );
};

export default AddObservation;
