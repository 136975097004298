import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "./screens/Login";
import NavbarMenu from "./components/NavbarMenu";
import registration from "./screens/Register/Registration";
import lockscreen from "./screens/Auth/Lockscreen";
import forgotpassword from "./screens/Register/ForgotPassword";
import page404 from "./screens/Auth/Page404";
import page404_LoggedIn from "./screens/Auth/Page404_LoggedIn";
import page403 from "./screens/Auth/Page403";
import page500 from "./screens/Auth/Page500";
import page503 from "./screens/Auth/Page503";
import VerifyEmailAddress from "./screens/Register/VerifyEmailAddress";
import JoinSchool from "./screens/Register/JoinSchool";
import CreateSchool from "./screens/Register/CreateSchool";
import Dashboard from "./screens/Dashbord/Dashboard";
import Student from "./screens/Dashbord/Student/List";
import ProfileTabs from "./screens/Dashbord/Student/ProfileTabs";
import UserContactDetails from "./screens/Dashbord/Student/UserContactDetails";
import UserFeed from "./screens/Dashbord/Student/UserFeed";
import Staff from "./screens/Dashbord/Staff/Staff";
import ParentsDashboard from "./screens/ParentsDashboard/Dashboard/ParentsDashboard";
import AddChild from "./screens/ParentsDashboard/AddChild";
import MealPlans from "./screens/Dashbord/MealPlan/MealPlanList";
import PickupInfo from "./screens/Dashbord/PickupInfo/PickupInfoList";
import TimingList from "./screens/Dashbord/Timing/TimingList";
import Permission from "./screens/Dashbord/Permission/Permission";
import Role from "./screens/Dashbord/Permission/Role";
import Addstaff from "./screens/Dashbord/Staff/addstaff";
import WaitingSchoolConfirmation from "./screens/Register/WaitingSchoolConfirmation";
import activity from "./screens/Activity/Activity";
import appCalendar from "./screens/App/Calendar";
import ActivityCategoryList from "./screens/Dashbord/ActivityCategoryList/ActivityCategoryList";
import ParentNavbarMenu from "./components/ParentNavbarMenu";
import Util from "../src/Util";
import Announcement from "./screens/Dashbord/Announcement/Announcement";
import ParentsGetAnnouncement from "./screens/ParentsDashboard/Announcement/ParentsGetAnnouncement";
import Mychildren from "./screens/ParentsDashboard/MyChild/Mychildren";
import room from "./screens/Dashbord/ClassRoom/room";
import SwitchSchool from "./components/SchoolDetails/SwitchSchool";
import Teacher from "./screens/Dashbord/ClassRoom/Teacher";
import Subject from "./screens/Dashbord/Subject/Subject";
import Maintenance from "./Maintenance";
import ResetPassword from "./screens/Register/ResetPassword";
import ActivityDetailsPage from "./screens/Dashbord/ActivityDetailsPage/ActivityDetailsPage";
import Group from "./screens/Dashbord/Group/Group";
import AddGroup from "./screens/Dashbord/Group/AddGroup";
import { SocketContext, socket } from "./context/SocketContext";
import SelectRole from "./screens/Register/SelectRole";
import BulkStudent from "./screens/Dashbord/Student/BulkStudent";
import MyProfileTab from "./screens/MyProfile/MyProfileTab";
import ChildProfile from "./screens/ParentsDashboard/MyChild/ChildProfile";
import StaffTab from "./screens/Dashbord/Staff/StaffTab/StaffTab";
import LeaveRequestList from "./screens/ParentsDashboard/LeaveRequestList/LeaveRequestList";
import LeaveTab from "./screens/Dashbord/Attendence/LeaveTab/LeaveTab";
import EditContactTab from "./screens/Dashbord/Student/Edit Contact/EditContactTab";
import SchoolDetails from "./components/SchoolDetails/SchoolDetails";
import FeeTypes from "./screens/Dashbord/Fee Types/FeeTypes";
import StudentBalanceList from "./screens/Dashbord/BillingPlanStudents/StudentBalanceList";
import StudentBillDetails from "./screens/Dashbord/BillingPlanStudents/StudentBillDetails";
import Message from "./screens/Message/Message";
import BillDashboard from "./screens/Dashbord/BillingPlanDashboard/BillDashboard";
import BillingReports from "./screens/Dashbord/BillingReports/BillingReports";
import DashboardTab from "./screens/Dashbord/BillingPlanDashboard/DashboardTab";
import PendingTab from "./screens/ParentsDashboard/Billing/Pending/PendingTab";
import Failure from "./screens/ParentsDashboard/Billing/Failure";
import Success from "./screens/ParentsDashboard/Billing/Success";
import AllPaymentList from "./screens/ParentsDashboard/Billing/Transactions/allPaymentList";
import ParentDetails from "./screens/Dashbord/Student/ParentDetails";
import AttendanceLog from "./screens/Dashbord/Attendence/DailyAttendance/AttendanceLog";
import AddNewStudent from "./screens/Add New/AddNewStudent";
import Settings from "./screens/Dashbord/Settings/Settings";
import AttendanceDetails from "./screens/Dashbord/Attendence/DailyAttendance/AttendanceDetails";
import Terms from "./screens/Dashbord/Terms/Terms";
import ChildActivity from "./screens/ParentsDashboard/ChildActivity/ChildActivity";
import AddNewStaff from "./screens/Dashbord/Staff/AddNewStaff/AddNewStaff";
import BulkStaff from "./screens/Dashbord/Staff/AddNewStaff/BulkStaff";
import Home from "./screens/Dashbord/Home/Home";
import ErrorBoundary from "./ErrorBoundry";
import Reporting from "./screens/Dashbord/Reporting/Reporting";
import Topbar from "./components/Topbar";
import ParentTopbar from "./components/ParentTopbar";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import Admin from "./screens/Admin/Admin";
import SetPassword from "./screens/Register/SetPassword";
import VerifyEmail from "./screens/Register/VerifyEmail";
import ThanksPage from "./screens/Register/ThanksPage";
import Parent from "./screens/Dashbord/Parent/Parent";
import Observations from "./screens/Dashbord/Observations/Observations";
import StudentObservation from "./screens/Dashbord/Observations/StudentObservation";
// import DirectionProvider,{DIRECTIONS} from "react-with-direction/dist/DirectionProvider"

window.__DEV__ = true;

const App = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const user = Util.getUser();
  const ParentMode = Util.getParentMode();
  const location = useLocation();
  var res = location.pathname;
  var baseUrl = process.env.PUBLIC_URL;
  baseUrl = baseUrl.split("/");
  res = res.split("/");
  res = res.length > 0 ? res[baseUrl.length] : "/";
  res = res ? res : "/";

  const activeKey1 =
    res === "" || res === "/"
      ? user !== null
        ? ParentMode
          ? "ParentsDashboard"
          : "Dashboard"
        : res
      : res;

  return (
    // <DirectionProvider direction={DIRECTIONS.RTL}>
    <SocketContext.Provider value={socket}>
      <div id="wrapper">
        {activeKey1 === "" ||
        activeKey1 === "/" ||
        activeKey1 === "login" ||
        activeKey1 === "resetPassword" ||
        activeKey1 === "registration" ||
        activeKey1 === "verifyEmailAddress" ||
        activeKey1 === "JoinSchool" ||
        activeKey1 === "CreateSchool" ||
        activeKey1 === "waitingSchool" ||
        activeKey1 === "ThanksPage" || 
        activeKey1 === "lockscreen" ||
        activeKey1 === "forgotpassword" ||
        activeKey1 === "page404" ||
        activeKey1 === "page403" ||
        activeKey1 === "page500" ||
        activeKey1 === "page503" ||
        user == null ? (
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={Login}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              component={Login}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/Select-Role`}
              component={SelectRole}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/setPassword`}
              component={SetPassword}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/resetPassword`}
              component={ResetPassword}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/verifyEmailAddress`}
              component={VerifyEmailAddress}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/verifyEmail`}
              component={VerifyEmail}
            />
            <Route exact path="/JoinSchool" component={JoinSchool} />
            <Route exact path="/CreateSchool" component={CreateSchool} />
            <Route exact path={`${process.env.PUBLIC_URL}/ThanksPage`} component={ThanksPage} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/forgotpassword`}
              component={forgotpassword}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/waitingSchool`}
              component={WaitingSchoolConfirmation}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page404`}
              component={page404}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page403`}
              component={page403}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page500`}
              component={page500}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page503`}
              component={page503}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/registration`}
              component={registration}
            />
            <Route exact path={`registration`} component={registration} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/lockscreen`}
              component={lockscreen}
            />
            <Route path="*" component={page404} />
          </Switch>
        ) : (
          <>
            {ParentMode ? (
              <ParentNavbarMenu history={history} activeKey={activeKey1} />
            ) : (
              <NavbarMenu history={history} activeKey={activeKey1} />
            )}
            <div id="main-content">
              {/* <nav className=" header-border"> */}
              {ParentMode ? <ParentTopbar /> : <Topbar />}

              {/* </nav> */}

              <Switch>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  component={ParentMode ? ParentsDashboard : Home}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/school-details`}
                  component={SchoolDetails}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/Dashboard-Activity`}
                  component={ActivityDetailsPage}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/NewsFeed`}
                  component={Dashboard}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/Dashboard`}
                  component={Home}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/new-student`}
                  component={AddNewStudent}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/student`}
                  component={Student}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/edit-contact`}
                  component={EditContactTab}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/BulkStudent`}
                  component={BulkStudent}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/Room-management`}
                  component={room}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/profileTabs`}
                  component={ProfileTabs}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/parentContact`}
                  component={ParentDetails}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/userContact`}
                  component={UserContactDetails}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/userFeed`}
                  component={UserFeed}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/staff`}
                  component={Staff}
                />
                 <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/parent`}
                  component={Parent}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/BulkStaff`}
                  component={BulkStaff}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/add-staff`}
                  component={Addstaff}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/new-staff`}
                  component={AddNewStaff}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/staff-update`}
                  component={StaffTab}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/attendance-list`}
                  component={AttendanceDetails}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/observation-list`}
                  component={Observations}
                />
                 <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/studentObservations`}
                  component={StudentObservation}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/group`}
                  component={Group}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/reporting`}
                  component={Reporting}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/settings`}
                  component={Settings}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/mealplans`}
                  component={MealPlans}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/pickupinfo`}
                  component={PickupInfo}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/timingList`}
                  component={TimingList}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/FeeTypes`}
                  component={FeeTypes}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/bill-Dashboard`}
                  component={DashboardTab}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/billing-plan-list`}
                  component={StudentBalanceList}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/bill-reports`}
                  component={BillingReports}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/student-bill-details`}
                  component={StudentBillDetails}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/activityCategoryList`}
                  component={ActivityCategoryList}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/role`}
                  component={Role}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/permission`}
                  component={Permission}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/switch-school`}
                  component={SwitchSchool}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/messaging`}
                  component={Message}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/activity`}
                  component={activity}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/announcement`}
                  component={Announcement}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/get-announcement`}
                  component={ParentsGetAnnouncement}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/appcalendar`}
                  component={appCalendar}
                />
                {/* parent Tabs */}

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/ParentsDashboard`}
                  component={ParentsDashboard}
                  onToastMessage={(value) => {
                    this.setState({
                      isToast: value,
                    });
                  }}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/leave-request-list`}
                  component={LeaveRequestList}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/ChildActivity`}
                  component={ChildActivity}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/Success`}
                  component={Success}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/Failure`}
                  component={Failure}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/invoices`}
                  component={PendingTab}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/payments`}
                  component={AllPaymentList}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/addChild`}
                  component={AddChild}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/myChild`}
                  component={Mychildren}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/child-profile`}
                  component={ChildProfile}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/attendance-log`}
                  component={AttendanceLog}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/terms`}
                  component={Terms}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/leave-approval`}
                  component={LeaveTab}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/teacher`}
                  component={Teacher}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/subject`}
                  component={Subject}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/my-profile`}
                  component={MyProfileTab}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admin`}
                  component={Admin}
                />
                <Route path="*" component={page404_LoggedIn} />
              </Switch>
            </div>
          </>
        )}
      </div>
    </SocketContext.Provider>
    //  </DirectionProvider>
  );
};

const mapStateToProps = ({ loginReducer }) => ({
  isLoggedin: loginReducer.isLoggedin,
});

export default withRouter(connect(mapStateToProps, {})(App));
