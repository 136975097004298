import { Box, SwipeableDrawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import Close from "../../../assets/Icon/close.png";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import ApiCalling from "../../../network/ApiCalling";
import moment from "moment";
import { observations } from "../../CommonImage";
import { PictureAsPdf } from "@mui/icons-material";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ListAltIcon from "@mui/icons-material/ListAlt";

const ViewObservation = (props) => {
  const show = props.show;
  const onClose = props.onClose;
  const id = props.observationById;
  const { t } = useTranslation();
  const getLanguage = Util.getSelectedLanguage();
  const [data, setData] = useState({});
  const school = Util.getSelectedSchool();
  const photoREF = React.useRef([]);

  const actById = () => {
    ApiCalling.apiCallParamsGet(`observations/get-id/${id}`)
      .then((res) => {
        // console.log("res", res);
        if (res?.data?.observations[0]) {
          const data = res?.data?.observations[0];
          setData(data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (id) {
      actById();
    }
  }, [id]);

  const isImage = (attachment) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const extension = attachment.split(".").pop();
    return imageExtensions.includes(extension.toLowerCase());
  };

  const isVideo = (attachment) => {
    const videoExtensions = ["mp4"];
    const extension = attachment.split(".").pop();
    return videoExtensions.includes(extension.toLowerCase());
  };

  const isDocument = (attachment) => {
    const docExtensions = ["pdf", "doc", "docx", "xls", "xlsx"];
    const extension = attachment.split(".").pop();
    return docExtensions.includes(extension.toLowerCase());
  };
  const getDocumentIcon = (attachment) => {
    const extension = attachment.split(".").pop().toLowerCase();
    switch (extension) {
      case ("xls", "xlsx"):
        return <ListAltIcon fontSize="large" className="text-color-primary" />;
      case "pdf":
        return <PictureAsPdf fontSize="large" className="text-color-primary" />;
      case ("doc", "docx"):
        return (
          <FolderCopyIcon fontSize="large" className="text-color-primary" />
        );

      default:
        return (
          <FolderCopyIcon fontSize="large" className="text-color-primary" />
        );
    }
  };

  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={show}
        onClose={() => {
          onClose();
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{
            position: "relative",
            width: "100%",
            height: "calc(100vh - 64px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">View Observations</div>
              <button className="hidden-btn">
                <img
                  src={Close}
                  alt="Close"
                  className="svg-icon"
                  onClick={() => {
                    onClose();
                  }}
                  height={20}
                  width={20}
                />
              </button>
            </div>
            <hr style={{ backgroundColor: "#E5E7EB" }} />
            <div className="body mt-3">
              <div className="row mt-2">
                <div
                  className="d-flex justify-content-between align-items-center mb-1"
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  <div>Observation Details</div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-12">
                  <label style={{ color: "#4B5563", marginTop: "10px" }}>
                    Title
                  </label>
                  <div style={{ fontWeight: "600", fontSize: "16px" }}>
                    {data?.title}
                  </div>
                </div>

                <div className="col-md-12">
                  <label style={{ color: "#4B5563", marginTop: "10px" }}>
                    Description
                  </label>
                  <div style={{ fontWeight: "600", fontSize: "16px" }}>
                    {data?.description ? data?.description : "-"}
                  </div>
                </div>

                <div className="col-md-12">
                  <label style={{ color: "#4B5563", marginTop: "10px" }}>
                    Next Steps
                  </label>
                  <div style={{ fontWeight: "600", fontSize: "16px" }}>
                    {data?.next_steps ? data?.next_steps : "-"}
                  </div>
                </div>

                <div className="col-md-6">
                  <label style={{ color: "#4B5563", marginTop: "10px" }}>
                    Type
                  </label>
                  <div style={{ fontWeight: "600", fontSize: "16px" }}>
                    {data?.ObservationType?.type}
                  </div>
                </div>

                <div className="col-md-6">
                  <label style={{ color: "#4B5563", marginTop: "10px" }}>
                    Date
                  </label>
                  <div style={{ fontWeight: "600", fontSize: "16px" }}>
                    {data?.date
                      ? moment(data?.date).format(
                          Util.getMomentDateTimeFormat(school.date_format)
                        )
                      : "-"}
                  </div>
                </div>

                {/* <div className="col-md-6">
                  <label style={{ color: "#4B5563", marginTop:"10px"}}>Shared with Parents</label>
                  <div style={{ fontWeight: "600", fontSize: "16px" }}>
                    {data?.share_with_parent ? data?.share_with_parent : "-"}
                  </div>
                </div> */}

                <div className="col-md-12">
                  <div
                    style={{
                      color: "#4B5563",
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontWeight: 600,
                    }}
                  >
                    Media
                  </div>
                  {data?.ObservationMedia?.length ? (
                    <div>
                      {isImage(data?.ObservationMedia[0]?.name) && (
                        <img
                          crossOrigin="anonymous"
                          style={{
                            position: "relative",
                            maxWidth: "310px",
                            maxHeight: "200px",
                          }}
                          ref={photoREF}
                          src={`${observations}${data?.ObservationMedia[0]?.name}`}
                        />
                      )}
                      {isVideo(data?.ObservationMedia[0]?.name) && (
                        <video
                          className="video-responsive"
                          style={{
                            maxHeight: "300px",
                            borderRadius: "10px",
                            padding: "5px",
                          }}
                          src={`${observations}${data?.ObservationMedia[0]?.name}`}
                          controls
                        />
                      )}
                      {isDocument(data?.ObservationMedia[0]?.name) && (
                        <a
                          href={`${observations}${data?.ObservationMedia[0]?.name}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ display: "block", width: "fit-content" }}
                        >
                          {getDocumentIcon(data?.ObservationMedia[0]?.name)}
                          <br />
                          {data?.ObservationMedia[0]?.name}
                        </a>
                      )}
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default ViewObservation;
