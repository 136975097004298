import React, { useEffect, useState } from "react";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/common/Loader";
import Sort from "../../../assets/Icon/Short.svg";
import ApiCalling from "../../../network/ApiCalling";
import { Button, Modal } from "react-bootstrap";
import Close from "../../../assets/Icon/close.png";
import { ModalContent } from "semantic-ui-react";
import Invalid from "../../../assets/Icon/invalid.svg";
import ToastHOC from "../../HOC/ToastHOC";
import ModalComponent from "../ModalComponent";

const ObservationType = (props) => {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const [showObservation, setShowObservation] = useState(false);
  const [observation, setObservation] = useState("");
  const [observationError, setObservationError] = useState("");
  const [editValue, setEditValue] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [observationList, setObservationList] = useState([]);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [sortColumn, setSortColumn] = useState("Tax");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    setSortColumn(columnName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedObservationList = [...observationList].sort((a, b) => {
    if (sortColumn === "observationType") {
      return sortDirection === "asc"
        ? a.type.localeCompare(b.type)
        : b.type.localeCompare(a.type);
    }
    return 0;
  });

  const handleAddObservation = () => {
    setShowObservation(true);
    setEditValue(null);
    setObservation("");
    setObservationError("");
  };

  const handleClose = () => {
    setShowObservation(false);
    setObservation("");
    setObservationError("");
  };

  const getObservationList = () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("observation-type/list")
      .then((res) => {
        setObservationList(res?.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSaveAndClose = () => {
    setObservationError("");
    let error = false;
    if (observation === "") {
      setObservationError(t("Observation is required"));
      error = true;
    }

    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);
      if (editValue !== null) {
        const data = {
          id: editValue.id,
          type: observation,
        };

        ApiCalling.apiCallBodyDataPost("observation-type/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setShowObservation(false);
              setIsLoading(false);
              setIsToast(res.data.message);

              getObservationList();
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      } else {
        const data = {
          type: observation,
        };
        ApiCalling.apiCallBodyDataPost("observation-type/create", data)
          .then((res) => {
            if (res.data.status === true) {
              setShowObservation(false);
              setIsLoading(false);
              setIsToast(res.data.message);

              getObservationList();
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }
    }
  };

  useEffect(() => {
    getObservationList();
  }, []);

  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`observation-type/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setIsToast(t("Observations Deleted Successfully"));
        setShowDeleteConfirmation(false);
        getObservationList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="header text-right px-0 mb-2">
            <button
              className={`${
                Util.hasPermission("MASTERS_ADD")
                  ? "btn custom-primary"
                  : "no-permission"
              }`}
              type="button"
              aria-expanded="false"
              style={{ color: "#fff", padding: "5px, 10px" }}
              onClick={handleAddObservation}
            >
              {t("Add Observation")}
            </button>
          </div>
        </div>

        <Modal
          show={showObservation}
          onHide={handleClose}
          animation={true}
          centered={true}
          dialogClassName="modal-view"
          onShow={() => {
            setIsClickedOnce(false);
            if (editValue !== null) {
              setObservation(editValue.type);
            }
          }}
        >
          <div className="d-flex justify-content-between align-items center p-4 border-bottom">
            <h6>
              {editValue !== null
                ? t("Edit Observation ")
                : t("Add Observation")}
            </h6>

            <button className="hidden-btn" onClick={handleClose}>
              <img src={Close} className="svg-icon" height={20} width={20} />
            </button>
          </div>
          <ModalContent isLoading={isLoading}>
            <Modal.Body>
              <div className="form-group text-start">
                <label className="basic-text required">
                  {t("Observation")}
                </label>
                <input
                  className={`form-control ${
                    observationError.length > 0 ? "input-error" : "custom-input"
                  }`}
                  placeholder={t("Enter Observation Type")}
                  type="text"
                  value={observation}
                  onChange={(event) => {
                    setObservation(event.target.value);
                    setObservationError("");
                  }}
                />
                {observationError.length > 0 && (
                  <span className="text-danger invalid-error">
                    <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                    {observationError}
                  </span>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn custom-primary"
                onClick={handleSaveAndClose}
              >
                {t("Save")}
              </Button>
            </Modal.Footer>
          </ModalContent>
        </Modal>

        <div className="row clearfix ">
          <div className="table-div overflow-hidden mt-2 p-0">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="body">
                <>
                  {!isLoading ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">
                            {t("Observation Type")}{" "}
                            <img
                              src={Sort}
                              className="ml-1 fa-sort"
                              alt=""
                              onClick={() => {
                                handleSort("observationType");
                              }}
                            />
                          </th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      {observationList.length > 0 ? (
                        <tbody>
                          {sortedObservationList.map((t, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{t?.type}</td>
                                <td>
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setEditValue(t);
                                      setShowObservation(true);
                                    }}
                                    className={`${
                                      !Util.hasPermission("MASTERS_EDIT") &&
                                      "no-permission"
                                    }`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </a>
                                </td>
                                <td>
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowDeleteConfirmation(true);
                                      setDeleteId(t.id);
                                    }}
                                    className={`${
                                      Util.hasPermission("MASTERS_DELETE")
                                        ? "pl-5"
                                        : "no-permission"
                                    }`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i className="fa fa-trash-o text-danger"></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      ) : (
                        <tfoot>
                          <tr>
                            <td colSpan={4}>
                              {t(
                                "There are no Observation. Click the 'Add Observation ' button to create one."
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      )}
                    </table>
                  ) : (
                    <Loader />
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        show={showDeleteConfirmation}
        onHide={() => {
          setShowDeleteConfirmation(false);
        }}
        title={t("Delete")}
        onCancel={t("Cancel")}
        onConfirm={t("Confirm")}
        subTitle={t("Are you sure, to delete this Observations?")}
        size="sm"
        onCancelClick={() => {
          setShowDeleteConfirmation(false);
        }}
        onConfirmClick={handleDelete}
      />
    </div>
  );
};

export default ToastHOC(ObservationType);
