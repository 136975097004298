import React, { useEffect, useState } from "react";
import Util from "../../../Util";
import ToastHOC from "../../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import Select from "react-dropdown-select";
import Calender from "../../../assets/Icon/calendar.svg";
import DatePicker from "react-datepicker";
import AddObservation from "./AddObservation";
import ApiCalling from "../../../network/ApiCalling";
import Loader from "../../../components/common/Loader";
import Sort from "../../../assets/Icon/Short.svg";
import { imageStudentList } from "../../CommonImage";
import { Avatar } from "@mui/material";
import { useHistory } from "react-router-dom";
import Search from "../../../assets/Icon/Search.svg";
import close from "../../../assets/Icon/close.svg";
import moment from "moment";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Pagination from "@mui/material/Pagination";
import { DateRangePicker } from "rsuite";

const Observations = (props) => {
  const { t } = useTranslation();
  const { setIsToast, setIsSubToast } = props;
  const history = useHistory();
  const getLanguage = Util.getSelectedLanguage();
  const [isVisible, setIsVisible] = useState(false);
  const [observationById, setObservationById] = useState(null);
  const [observationTypeId, setObservationTypeId] = useState(null);
  const [observationType, setObservationType] = useState([]);
  const [observatioList, setObservationList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [page_number, setPage_number] = useState(0);
  const [classId, setClassId] = useState(0);
  const [classList, setClassList] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [gradeList, setGradeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [hasClassRoom, setHasClassRoom] = useState();
  const school = Util.getSelectedSchool();
  const [recordPerPage, setRecordPerPage] = useState(10);

  const ChangeDateRange = (range) => {
    if (range) {
      setCurrentPage(1);
      setObservationList([]);
      setDate(new Date(range[0]));
      setEndDate(new Date(range[1]));
    }
  };

  const handleCleanDate = () => {
    setCurrentPage(1);
    setObservationList([]);
    setDate();
    setEndDate();
  };

  const CustomInput = React.forwardRef(({ value, onClick, icon }, ref) => (
    <div className="d-flex" onClick={onClick}>
      <input
        ref={ref}
        className="custom-input__field"
        value={value}
        readOnly
        placeholder="Date"
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calendar" className="svg-icon calender-icon" />
      </div>
    </div>
  ));

  const getObservationTypeList = () => {
    ApiCalling.apiCallParamsGet("observation-type/list")
      .then((res) => {
        setObservationType(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeType = (data) => {
    setCurrentPage(1);
    setObservationTypeId(data[0].id);
  };

  const changeClassRoom = (data) => {
    setCurrentPage(1);
    // setActivityList([]);
    setClassId(data[0].id);
  };

  const changeGrade = (data) => {
    setCurrentPage(1);
    // setActivityList([]);
    setClassId(0);
    setGradeId(data[0].id);
  };

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const getClassList = async () => {
    setLoading(true);
    ApiCalling.apiCallBodyDataGet("classroom/list").then((res) => {
      const modifiedClassList = res.data
        .filter((g) => gradeId == 0 || g.Grade.id == gradeId)
        .map((classroom) => ({
          id: classroom.id,
          name: `${classroom.name}`,
        }));
      setClassList(modifiedClassList);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const getGradeList = async () => {
    setLoading(true);
    ApiCalling.apiCallBodyDataGet("grade/list").then((res) => {
      setGradeList(res.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const getObservationList = () => {
    if (date) {
      const selectedDate = new Date(date);
      const utcDate = new Date(
        Date.UTC(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate()
        )
      );
      var isoDate = utcDate.toISOString().split("T")[0];
    }
    if (endDate) {
      const selectedDate = new Date(endDate);
      const utcDate = new Date(
        Date.UTC(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate()
        )
      );
      var endISODate = utcDate.toISOString().split("T")[0];
    }

    const data = {
      classroom_id: classId,
      grade_id: gradeId,
      page_size: recordPerPage,
      page_number: currentPage,
      str_search: searchClick,
      ascdesc: sortOrder ? "desc" : "asc",
      order_by: sortBy,
      date: isoDate,
      type_id: observationTypeId,
      end_date: endISODate,
    };
    setLoading(true);
    ApiCalling.apiCallBodyDataPost("observations/listByStudent", data)
      .then((res) => {
        setObservationList(res?.data?.data);
        setTotalPages(res.data.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getObservationTypeList();
    getClassList();
    getGradeList();
    getObservationList();
  }, []);

  const searchClicked = () => {
    if (search.length > 0) {
      setSearch("");
      setSearchClick("");
    } else {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };

  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };

  useEffect(() => {
    getObservationList();
  }, [
    sortBy,
    sortOrder,
    recordPerPage,
    classId,
    gradeId,
    searchClick,
    currentPage,
    date,
    observationTypeId,
  ]);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="container-fluid">
        <div className="body">
          <div className="col-lg col-md-12 p-3">
            <div className="row py-3">
              <div className="col-md-4 text-start">
                <h2 className="page-head">{t("Observations")}</h2>
              </div>
              <div
                className={`col-md-8  ${getLanguage !== "ar" && "text-end"}`}
              >
                <div className="d-flex justify-content-end align-items-center gap-2">
                  <button
                    // className={`${
                    //   Util.hasPermission("MASTERS_ADD")
                    //     ? "btn primary-sm-btn"
                    //     : "no-permission"
                    // }`}
                    className="btn primary-sm-btn"
                    type="button"
                    onClick={() => {
                      console.log("im visble");
                      setIsVisible(true);
                    }}
                  >
                    {t("+ Add New")}
                  </button>
                </div>
              </div>
            </div>

            <div className={"table-div"}>
              <div className="d-flex gap-20 align-items-center table-sort-bar">
                <div className="custom-search-wrap">
                  <div className="input-group">
                    <input
                      className="form-control search "
                      placeholder={t("Search Student")}
                      type="text"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      onKeyPress={(e) => EnterHandler(e)}
                    />
                    <div className="input-group-prepend">
                      <div className="search-icon">
                        <img
                          src={search.length > 0 ? close : Search}
                          className="svg-icon"
                          alt=""
                          onClick={searchClicked}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mr-2">
                  {/* <DatePicker
                    placeholderText="Select Date"
                    selected={date}
                    onChange={ChangeDateRange}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    popperPlacement="bottom"
                    customInput={<CustomInput icon={Calender} />}
                    maxDate={new Date()}
                  /> */}
                  <DateRangePicker
                    className="mr-2 custom-range-picker"
                    onChange={(range) => ChangeDateRange(range)}
                    onClean={handleCleanDate}
                    placeholder="Select Date"
                  />
                </div>
                <div className="mr-2">
                  <Select
                    searchable={false}
                    className="select-custom text-nowrap"
                    options={[...observationType]}
                    onChange={(values) => changeType(values)}
                    style={{ width: "100%" }}
                    labelField="type"
                    values={[{ value: "all", type: "Type" }]}
                    valueField="id"
                  />
                </div>
                <div className="mr-2">
                  <Select
                    searchable={false}
                    className="select-custom"
                    placeholder="Grade"
                    options={[{ id: 0, title: "Grade" }, ...gradeList]}
                    onChange={(values) => changeGrade(values)}
                    style={{ width: "100%" }}
                    labelField="title"
                    values={[{ id: 0, title: "Grade" }]}
                    valueField="id"
                  />
                </div>
                <div className="mr-2">
                  <Select
                    searchable={false}
                    className="select-custom"
                    placeholder="Rooms"
                    options={[{ id: 0, name: "Rooms" }, ...classList]}
                    onChange={(values) => changeClassRoom(values)}
                    style={{ width: "100%" }}
                    labelField="name"
                    values={[{ id: 0, name: "Rooms" }]}
                    valueField="id"
                  />
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-wrapper">
                      <table className="table">
                        <thead>
                          <tr className="text-nowrap">
                            <th
                              scope="col"
                              className="check-col"
                              style={{
                                width: 0,
                              }}
                            >
                              {t("Students Name")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("students.first_name");
                                }}
                              />
                            </th>

                            <th
                              scope="col"
                              className="check-col"
                              style={{
                                width: 0,
                              }}
                            >
                              {t("Observations")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                // onClick={() => {
                                //   handleSort("observations");
                                // }}
                              />
                            </th>

                            <th
                              scope="col"
                              className="check-col"
                              style={{
                                width: 0,
                              }}
                            >
                              {t("Last Observation")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                // onClick={() => {
                                //   handleSort("student.first_name");
                                // }}
                              />
                            </th>

                            <th
                              scope="col"
                              className="check-col"
                              style={{
                                width: 0,
                              }}
                            >
                              {t("Grade")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("students.grade_id");
                                }}
                              />
                            </th>

                            <th
                              scope="col"
                              className="check-col"
                              style={{
                                width: 0,
                              }}
                            >
                              {t(
                                "Room                                                                                                                                                                                                                                      "
                              )}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("students.classroom");
                                }}
                              />
                            </th>
                          </tr>
                        </thead>

                        {observatioList?.length > 0 ? (
                          <>
                            <tbody>
                              {observatioList.map((item, index) => {
                                const recentObservation =
                                  item?.ObservationStudents?.map(
                                    (student) => student.Observations
                                  )
                                    .flat()
                                    .sort(
                                      (a, b) =>
                                        new Date(b.created_on) -
                                        new Date(a.created_on)
                                    )[0];
                                // console.log(recentObservation);
                                return (
                                  <tr>
                                    <td
                                      style={{
                                        // paddingLeft: "0px",
                                        width: 0,
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        {item.photo ? (
                                          <img
                                            className="rounded-circle "
                                            style={{
                                              width: "40px",
                                              height: "40px",
                                            }}
                                            src={imageStudentList + item.photo}
                                            alt=""
                                          />
                                        ) : (
                                          <Avatar
                                            aria-label="recipe"
                                            className="avatar-font"
                                          >
                                            {item.first_name.toUpperCase()[0] +
                                              item.last_name.toUpperCase()[0]}
                                          </Avatar>
                                        )}
                                        <a
                                          className="ml-3"
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            const queryParams =
                                              new URLSearchParams();
                                            queryParams.set(
                                              "studentId",
                                              item.id
                                            );

                                            // Construct the URL with the query parameter
                                            const newUrl = `/studentObservations?${queryParams.toString()}`;

                                            history.push({
                                              pathname: "/studentObservations",
                                              search: `?${queryParams.toString()}`,
                                              studentId: item.id,
                                              hasClassRoom: hasClassRoom,
                                              studentName:
                                                item.first_name +
                                                " " +
                                                item.last_name,
                                            });
                                          }}
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item.first_name} {item.last_name}
                                        </a>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        paddingRight: "0px",
                                        width: 0,
                                      }}
                                    >
                                      {item?.ObservationStudents?.length}
                                    </td>
                                    <td
                                      style={{
                                        paddingRight: "0px",
                                        width: 0,
                                      }}
                                    >
                                      {recentObservation
                                        ? moment(
                                            recentObservation?.Observations
                                              ?.date
                                          ).format(
                                            Util.getMomentDateTimeFormat(
                                              school.date_format
                                            )
                                          )
                                        : "-"}
                                    </td>
                                    <td
                                      style={{
                                        paddingRight: "0px",
                                        width: 0,
                                      }}
                                    >
                                      {item?.grade?.title}
                                    </td>
                                    <td
                                      style={{
                                        paddingRight: "0px",
                                        width: 0,
                                      }}
                                    >
                                      {item?.classroom?.name}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </>
                        ) : (
                          <>
                            <tfoot>
                              <tr>
                                <td colSpan={8}>
                                  {t(
                                    "No Observation Found! Please recheck the filter or Create One"
                                  )}
                                </td>
                              </tr>
                            </tfoot>
                          </>
                        )}
                      </table>
                    </div>
                    {totalPages > 0 && (
                      <div className="row m-0 table-footer border-top">
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ height: "72px" }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <div className="basic-text">
                              {t("Students Per Page")}
                            </div>
                            <select
                              style={{ width: "72px", cursor: "pointer" }}
                              className="select-dropdown custom-input"
                              value={recordPerPage}
                              onChange={(e) => {
                                setRecordPerPage(e.target.value);
                              }}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </div>

                          <Stack
                            spacing={2}
                            direction="row"
                            alignItems="center"
                          >
                            <div
                              className={`link-button ${
                                currentPage == 1 ? "disabled" : ""
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                handlePreviousClick();
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <KeyboardArrowLeftIcon />
                              <span className="basic-bold-text">
                                {t("Previous")}
                              </span>
                            </div>
                            <Pagination
                              count={totalPages}
                              page={currentPage}
                              onChange={(event, page) => {
                                setCurrentPage(page);
                              }}
                              size="small"
                              hideNextButton
                              hidePrevButton
                            />
                            <div
                              className={`link-button ${
                                currentPage == totalPages ? "disabled" : ""
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleNextClick();
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <span className="basic-bold-text">
                                {t("Next")}
                              </span>
                              <KeyboardArrowRightIcon />
                            </div>
                          </Stack>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <AddObservation
        show={isVisible}
        onClose={() => {
          setIsVisible(false);
        }}
        observationById={observationById}
        onSave={(saveMsg) => {
          setIsVisible(false);
          getObservationList();
          setIsToast(saveMsg);
          if (
            observationById === null
              ? setIsSubToast("You have successfully added new Observation")
              : setIsSubToast("You have successfully updated Observation")
          )
            setObservationById(null);
        }}
      />
    </div>
  );
};

export default ToastHOC(Observations);
